@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

body {
  background: #18181b;
  font-family: "Raleway", sans-serif;
}
.f-f-h {
  font-family: "Josefin Sans", sans-serif;
}
.custom-bold {
  font-weight: 400;
  transition-property: all;
  transition-duration: 300ms;
  letter-spacing: 0.235px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.custom-bold:hover {
  font-weight: 700;
  letter-spacing: 0px;
}
.max-h-none {
  max-height: 0px;
  animation-name: closeIt;
  animation-duration: 0.7s;
}
.max-h-ease {
  animation-name: expandIt;
  animation-duration: 0.7s;
  max-height: 200px;
}
.footer {
  background-image: url(./resources/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@keyframes expandIt {
  from {
    max-height: 0;
  }
  to {
    max-height: 140px;
  }
}
@keyframes closeIt {
  from {
    max-height: 140px;
  }
  to {
    max-height: 0px;
  }
}
/* slider css */
.swiper-container {
  width: 900px !important;
  height: 536px !important;
}

.swiper-slide {
  height: 536px !important;
  width: 360px !important;
}
.swiper-slide > img {
  border: 4px solid #f2d9ae;
}
.swiper-slide-active > img {
  width: 348px !important;
  height: 418px !important;
}

.swiper-slide-prev > img {
  height: 395px !important;
}
.swiper-slide-next > img {
  height: 395px !important;
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: unset !important;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: unset !important;
}
.swiper-button-prev {
  width: 56px !important;
  height: 56px !important;
  margin-top: -94px !important;
  margin-left: -100px !important;
}
.swiper-button-next {
  width: 56px !important;
  height: 56px !important;
  margin-top: -94px !important;
  margin-right: -100px !important;
}
.swiper-pagination-bullet-active {
  width: 10px !important;
  height: 10px !important;
  background: #ebbc63 !important;
}
.swiper-pagination-bullet-active-next {
  width: 8px !important;
  height: 8px !important;
  background: #d2a652 !important;
}
.swiper-pagination-bullet-active-prev {
  width: 8px !important;
  height: 8px !important;
  background: #d2a652 !important;
}
.swiper-pagination-bullet-active-next-next {
  background: #d2a652 !important;
}
.swiper-pagination-bullet-active-prev-prev {
  background: #d2a652 !important;
}

.swiper-pagination {
  margin-bottom: 36px !important;
}

.swiper-pagination ~ span {
  left: unset;
}
/* mobile sliders css */
.horizontalSlider___281Ls {
  width: 600px !important;
  height: 613px !important;
}

/* custom animation */
.right-bounce {
  animation: bounce2 1s infinite;
}

@keyframes bounce2 {
  0%,
  100% {
    transform: translateX(30%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* media query */
@media (min-width: 1536px) {
  .swiper-button-prev {
    width: 56px !important;
    height: 56px !important;
    margin-top: -94px !important;
    margin-left: -64px !important;
  }
  .swiper-button-next {
    width: 56px !important;
    height: 56px !important;
    margin-top: -94px !important;
    margin-right: -64px !important;
  }
}
@media (min-width: 220px) and (max-width: 382px) {
  .horizontalSlider___281Ls {
    width: 277px !important;
    height: 214px !important;
  }
}
@media (min-width: 383px) and (max-width: 767px) {
  .horizontalSlider___281Ls {
    width: 360px !important;
    height: 360px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .horizontalSlider___281Ls {
    height: 530px !important;
  }
}
